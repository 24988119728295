import requestIdleCallback from 'lib/requestIdleCallback'
import { desktop } from 'styles/config/_breakpoints.scss'

const scrollTo = (el, onlyDesktop = true) => {
  const y = el.offsetTop
  requestIdleCallback(
    () =>
      window.innerWidth >= parseInt(onlyDesktop ? desktop : 0) &&
      window.scrollTo({ top: y, left: 0, behavior: 'smooth' }),
  )
}

const easeInOutQuad = function (t, b, c, d) {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t + b
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

/**
 *
 * @param {number} to Window Y
 * @param {number} duration
 * @param {function} cb Optional cb to call once scroll animation is finished.
 */
export const scrollToWindowY = (to, duration, cb) => {
  const element = document.scrollingElement
  const start = (element && element.scrollTop) || window.scrollY
  const change = to - start
  let currentTime = 0
  const increment = 10

  const animateScroll = (id) => {
    currentTime += increment
    const val = easeInOutQuad(currentTime, start, change, duration)
    window.scrollTo(0, val)
    if (currentTime < duration) {
      window.requestAnimationFrame(animateScroll)
    } else {
      id && window.cancelAnimationFrame(id)
      cb && cb()
    }
  }
  animateScroll()
}

export default scrollTo
