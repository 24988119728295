import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import useUIContext from 'context/ui'
import ViewportEnter from 'components/motion/ViewportEnter'
import { Content } from 'components/ui/BlogThumbnail'
import { BodyCopy } from 'components/ui/Typography'
import { CURSOR_ICONS } from 'components/ui/Cursor'
import Button from 'components/ui/Button'
import CursorHover from 'components/ui/CursorHover'
import Image from 'components/ui/Image'
import useIsDesktop from 'lib/useIsDesktop'

import s from './BlogThumbnail.module.scss'

import ms from './MaskedReveal.module.scss'
const cn = classNames.bind(s)
const maskCn = classNames.bind(ms)

/*
 * There is no support implemented for the combination
 * `expandOnHover` and reveal = 'mask'.
 * It will still work but will be visually broken.
 */
const BlogThumbnail = ({
  activeIndex,
  delay = 0,
  description,
  expandOnHover = false,
  index,
  onActiveChange,
  onAnimateOut,
  thumbnailSharp,
  title,
  reveal = 'default',
  view = {},
  isFeatured = false,
}) => {
  const [isActive, setIsActive] = useState(false)
  const [exiting, setExiting] = useState(false)
  const [hasExpanded, setHasExpanded] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [rootMargin, setRootMargin] = useState('25% 0% -25% 0%')
  const [disablePointerEvents, setDisablePointerEvents] = useState(true)
  const scrollDirection = useUIContext((s) => s.scrollDirection)
  const wrapperRef = useRef()
  const thumbnailRef = useRef()
  const isDesktop = useIsDesktop()

  const expandTimerId = useRef(null)
  const exitTimerId = useRef(null)
  const maskedReveal = reveal === 'mask'
  const [allMaskedRevealed, setAllMaskedRevealed] = useState(false)

  useEffect(() => {
    if (view.inView) {
      if (!maskedReveal) setTimeout(() => setDisablePointerEvents(false), 1000)
      else if (maskedReveal && allMaskedRevealed) setDisablePointerEvents(false)
    }
  }, [view.inView, maskedReveal, allMaskedRevealed])

  const hideContent = useMemo(() => {
    if (!expandOnHover) return false
    else return index + 1 === activeIndex || index - 1 === activeIndex
  }, [index, activeIndex, expandOnHover])

  const animateOut = useMemo(() => {
    return view.inView && view.transitionStatus === 'exiting'
  }, [view])

  /* Parent callback to handle exit of other sibling elements */
  useEffect(() => {
    if (animateOut && onAnimateOut) onAnimateOut()
  }, [animateOut, onAnimateOut])

  useEffect(() => {
    if (!wrapperRef.current) return
    wrapperRef.current.style.setProperty('--delay', delay)
  }, [wrapperRef])

  useEffect(() => {
    if (isDesktop) return
    const r = scrollDirection === -1 ? '25% 0% -25% 0%' : '-25% 0% 25% 0%'
    setRootMargin(r)
  }, [scrollDirection, isDesktop])

  /* Opacity change on mobile */
  const onEnter = useCallback(() => {
    if (isDesktop) return
    onActiveChange(index)
  }, [index, isDesktop])

  const activate = useCallback(() => {
    setIsActive(true)
    onActiveChange(index)
  }, [index, setIsActive, onActiveChange])

  /* 1. Expansion and opacity change on desktop */
  const onPointerEnter = useCallback(() => {
    if (!isDesktop || isFeatured) return

    clearInterval(exitTimerId?.current)
    if (activeIndex !== null && expandOnHover) expandTimerId.current = setTimeout(activate, 150)
    else activate()
  }, [activeIndex, activate, isDesktop, expandOnHover])

  /* 1.
   * Prevents gettting stuck without expanding if cursor moves really quick
   */
  const onPointerMove = useCallback(() => {
    if (!isDesktop || isFeatured) return
    if (activeIndex === null) activate()
  }, [activate, activeIndex])

  /* 1.  */
  const onPointerLeave = useCallback(() => {
    if (!isDesktop || isFeatured) return
    clearInterval(expandTimerId?.current)

    if (!expandOnHover) {
      setHasExpanded(true)
      setIsActive(false)
      if (activeIndex === index) onActiveChange(null)
    } else {
      exitTimerId.current = setTimeout(() => setExiting(true), 150)
    }
  }, [isDesktop, setExiting, onActiveChange, index, activeIndex, expandOnHover])

  const onAnimationEnd = useCallback(
    (e) => {
      if (exiting && e.target === thumbnailRef?.current) {
        setHasExpanded(true)
        setIsActive(false)
        setExiting(false)
        if (activeIndex === index) onActiveChange(null)
      }
    },
    [exiting, setHasExpanded, setExiting, setIsActive, activeIndex, index, thumbnailRef],
  )

  const to = useMemo(() => (view.transitioningTo ? `to_${view.transitioningTo.toLowerCase()}` : ''), [view])

  return (
    <CursorHover icon={CURSOR_ICONS.VIDEO_CONTROLS_RECORD}>
      <div
        className={cn('wrapper', view.inView && to, {
          animateIn: view.inView,
          animateOut,
          isClicked,
          isActive,
          hasExpanded,
          exiting,
          shouldExpand: expandOnHover,
          pointerNone: disablePointerEvents,
          isFeatured,
          passive: activeIndex !== null && activeIndex !== index,
        })}
        ref={wrapperRef}
        onPointerEnter={onPointerEnter}
        onPointerMove={onPointerMove}
        onPointerLeave={onPointerLeave}
        onAnimationEnd={onAnimationEnd}
        onClick={() => setIsClicked(true)}
        style={{ '--exiting-duration': '0.25s', '--expand-duration': '0.3s' }}
      >
        {maskedReveal && (
          <div className={maskCn('masks', { inView: view.inView })}>
            <span className={maskCn('mask')} />
            <span className={maskCn('mask')} onTransitionEnd={() => setAllMaskedRevealed(true)} />
          </div>
        )}
        <div className={maskedReveal ? maskCn('outer', { inView: view.inView }) : cn('outer')}>
          <div className={maskedReveal ? maskCn('inner', { inView: view.inView }) : cn('inner')}>
            <ViewportEnter onEnter={onEnter} once={false} threshold={0.66} rootMargin={rootMargin}>
              <div className={cn('thumbnail')} ref={thumbnailRef}>
                {/* Only render content if title exists, since components like `BlogSection`
                 * take care of their own content (using `Content`).
                 */}
                {title && (
                  <Content
                    className={cn('content', { hide: hideContent })}
                    title={title}
                    description={description}
                    expandOnHover={expandOnHover}
                    isActive={isFeatured || isActive}
                    exiting={exiting}
                    isFeatured={isFeatured}
                  />
                )}
                <div className={cn('imageWrapper')}>
                  <Image className={cn('image')} src={thumbnailSharp} />
                </div>
                {!expandOnHover && isActive && (
                  <Button to={null} className={cn('button')}>
                    <BodyCopy>Read post</BodyCopy>
                  </Button>
                )}
              </div>
            </ViewportEnter>
          </div>
        </div>
      </div>
    </CursorHover>
  )
}

BlogThumbnail.propTypes = {
  activeIndex: PropTypes.number,
  delay: PropTypes.string,
  description: PropTypes.string,
  expandOnHover: PropTypes.bool,
  index: PropTypes.number,
  onActiveChange: PropTypes.func,
  onAnimateOut: PropTypes.func,
  thumbnailSharp: PropTypes.object,
  title: PropTypes.string,
  view: PropTypes.object,
  reveal: PropTypes.oneOf(['default', 'mask']),
  isFeatured: PropTypes.bool,
}

export default BlogThumbnail
