import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'
import classNames from 'classnames/bind'
import { PrismicLink } from 'prismic'

import useIsDesktop from 'lib/useIsDesktop'
import ViewportEnter from 'components/motion/ViewportEnter'
import { Container } from 'components/layout'
import Clickable from 'components/ui/Clickable'
import useUIContext from 'context/ui'
import BlogThumbnail, { Content } from 'components/ui/BlogThumbnail'
import Shape from 'components/ui/Shape'

import s from './BlogSection.module.scss'
const cn = classNames.bind(s)

const SHAPES = ['rectangle', 'rewind', 'circle']
const _ = { exitLength: 1.2, exitDelay: 0, entryLength: 0, entryDelay: 2 }

const BlogPostLink = (props, _delay) => {
  const setCurrentTheme = useUIContext((s) => s.setCurrentTheme)
  const setPreventThemeChange = useUIContext((s) => s.setPreventThemeChange)
  return (
    <TransitionLink
      to="/blog/"
      exit={{ delay: _.exitDelay, length: _.exitLength, state: { to: 'BLOG_POST_PAGE' } }}
      entry={{ delay: _.entryDelay, length: _.entryLength }}
      aria-label="blog-post"
      onClick={() => {
        setTimeout(() => {
          /* Take control over theme updates temporarily to avoid flickering background color */
          setCurrentTheme('light')
          setPreventThemeChange(true)
        }, 650)
      }}
      {...props}
    />
  )
}

const LinkWrapper = ({ wrapper, children }) => wrapper(children)

const Items = ({
  onClick,
  posts,
  renderShapes,
  useCustomPageTransition,
  reveal,
  expandOnHover,
  transitionStatus,
  current,
  hasLargePadding = false,
}) => {
  const [inView, setInView] = useState([])
  const isDesktop = useIsDesktop()
  const [activeIndex, setActiveIndex] = useState(!isDesktop ? 0 : null)
  const [exiting, setExiting] = useState(false)
  const [z, setZ] = useState({})

  const onActiveChange = useCallback(
    (i) => {
      if (i !== null) {
        /* Bump z-index each time item is active */
        const values = Object.values(z)
        const max = values.length ? Math.max(...values) : 1
        setZ({ ...z, [i]: max + 1 })
      }
      setActiveIndex(i)
    },
    [z, setZ, setActiveIndex, activeIndex],
  )

  useEffect(() => {
    if (isDesktop) setActiveIndex(null)
  }, [isDesktop])

  return (
    <div className={cn('thumbnails')}>
      <Container className={cn('container', { hasLargePadding })}>
        <div className={cn('inner', { dimOnMobile: expandOnHover })}>
          {posts?.map((item, i) => {
            if (!item?.blog_post) return null

            const { title, thumbnailSharp, thumbnail_description, isFeatured } = item.blog_post
            const itemVisible = !!inView.find((i) => i === title[0].text)

            const active = activeIndex === i
            const style = { zIndex: z[i] ? z[i] : active ? 2 : 1 }

            const className = cn('thumbnail', {
              active,
              passive: activeIndex !== null && !active,
              exiting,
            })

            return (
              <LinkWrapper
                key={i}
                wrapper={(children) => {
                  if (useCustomPageTransition) {
                    return (
                      <BlogPostLink
                        key={i}
                        className={className}
                        style={style}
                        to={PrismicLink.url(item.blog_post)}
                        onClick={onClick && onClick}
                      >
                        {children}
                      </BlogPostLink>
                    )
                  }
                  return (
                    <Clickable className={className} style={style} to={PrismicLink.url(item.blog_post)}>
                      {children}
                    </Clickable>
                  )
                }}
              >
                <>
                  {/* 1. Content needs to be placed outside of `BlogThumbnail` when shapes are rendered */}
                  <ViewportEnter onEnter={() => setInView([...inView, title[0].text])} key={title[0].text}>
                    <div>
                      <BlogThumbnail
                        reveal={reveal}
                        activeIndex={activeIndex}
                        index={i}
                        expandOnHover={expandOnHover}
                        title={renderShapes ? null : title[0].text /* 1. */}
                        onActiveChange={onActiveChange}
                        onAnimateOut={() => setExiting(true)}
                        description={thumbnail_description?.[0]?.text || ''}
                        thumbnailSharp={thumbnailSharp}
                        view={
                          useCustomPageTransition
                            ? { inView: itemVisible, transitionStatus, transitioningTo: current?.state?.to || '' }
                            : { inView: itemVisible, transitionStatus: '', transitioningTo: '' }
                        }
                        delay={(i + 1) * 0.1 + 's'}
                        isFeatured={isFeatured}
                      />
                    </div>
                  </ViewportEnter>
                  {/* 1. */}
                  {renderShapes && (
                    <Content className={cn('content')} titleClassName={cn('title')} title={title[0].text} index={i}>
                      {SHAPES[i] && (
                        <Shape
                          className={cn('shape')}
                          containerClassName={cn('linkShape', `linkShape${i + 1}`)}
                          shape={SHAPES[i]}
                        />
                      )}
                    </Content>
                  )}
                </>
              </LinkWrapper>
            )
          })}
        </div>
      </Container>
    </div>
  )
}

Items.propTypes = {
  posts: PropTypes.array,
  renderShapes: PropTypes.bool,
  useCustomPageTransition: PropTypes.bool,
  reveal: PropTypes.string,
  transitionStatus: PropTypes.string,
  current: PropTypes.object,
  expandOnHover: PropTypes.bool,
  hasLargePadding: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Items
