import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import TransitionLink, { TransitionState } from 'gatsby-plugin-transition-link'
import classNames from 'classnames/bind'

import scrollTo from 'lib/scrollTo'
import { BodyCopy } from 'components/ui/Typography'
import Button from 'components/ui/Button'
import { Container } from 'components/layout'
import Items from './Items'

import s from './BlogSection.module.scss'
const cn = classNames.bind(s)

const _ = { exitLength: 1.2, exitDelay: 0, entryLength: 0, entryDelay: 2 }

const ShowBlogLink = (props, _delay) => (
  <TransitionLink
    to="/blog/"
    exit={{ delay: _.exitDelay, length: _.exitLength, state: { to: 'BLOG_PAGE' } }}
    entry={{ delay: _.entryDelay, length: _.entryLength }}
    {...props}
  />
)

const BlogSection = ({ title, posts, renderShapes, useCustomPageTransition, reveal, viewAllLabel = 'View all' }) => {
  const element = useRef()
  const onClick = useCallback(() => scrollTo(element.current, false), [element])
  return (
    <div className={cn('section')} ref={element}>
      <TransitionState>
        {({ transitionStatus, current } = {}) => {
          return (
            <>
              <Container>
                <nav
                  className={cn('navigation', {
                    transitionOut: useCustomPageTransition && transitionStatus === 'exiting',
                  })}
                >
                  {title && <BodyCopy as="h2">{typeof title === 'string' ? title : title[0].text}</BodyCopy>}

                  <div className={cn('btn')}>
                    <Button to={useCustomPageTransition ? null : '/blog/'}>
                      {useCustomPageTransition ? (
                        <ShowBlogLink onClick={onClick}>
                          <BodyCopy>{viewAllLabel}</BodyCopy>
                        </ShowBlogLink>
                      ) : (
                        <BodyCopy>{viewAllLabel}</BodyCopy>
                      )}
                    </Button>
                  </div>
                </nav>
              </Container>

              <Items
                onClick={onClick}
                posts={posts}
                renderShapes={renderShapes}
                useCustomPageTransition={useCustomPageTransition}
                reveal={reveal}
                transitionStatus={transitionStatus}
                current={current}
              />
            </>
          )
        }}
      </TransitionState>
    </div>
  )
}

BlogSection.propTypes = {
  title: PropTypes.any,
  posts: PropTypes.array,
  renderShapes: PropTypes.bool,
  viewAllLabel: PropTypes.string,
  useCustomPageTransition: PropTypes.bool,
  reveal: PropTypes.string,
}

export default BlogSection
