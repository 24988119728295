/**
 * runtime check for requestIdleCallback
 */
export const requestIdleCallback = (callback, { timeout = 100 } = {}) => {
  if ('requestIdleCallback' in window) {
    return window.requestIdleCallback(callback, { timeout })
  }
  return setTimeout(callback, 0)
}

export const cancelIdleCallback = (id) => {
  if ('cancelIdleCallback' in window) {
    return window.cancelIdleCallback(id)
  }
  return clearTimeout(id)
}

export default requestIdleCallback
