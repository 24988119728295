import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import Button from 'components/ui/Button'
import { Headline3, BodyCopy } from 'components/ui/Typography'
import SplitText from 'components/motion/SplitText'
import Spacer from 'components/ui/Spacer'

import s from './Content.module.scss'
const cn = classNames.bind(s)

const Content = ({
  children,
  className,
  description,
  exiting = false,
  expandOnHover = false,
  isActive = false,
  title,
  titleClassName,
  isFeatured = false,
}) => {
  return (
    <div className={cn('content', { isActive, exiting, expand: expandOnHover, isFeatured }, className)}>
      {title && (
        <Headline3 className={cn('title', titleClassName)}>
          <SplitText>{title}</SplitText>
        </Headline3>
      )}
      {children}
      {expandOnHover && (
        <div className={cn('descriptionWrapper', { short: !description })}>
          <Spacer initial="1rem" medium="2.8vw" hideOnMobile={!isFeatured} />
          <div className={cn('description')}>
            <BodyCopy className={cn('descriptionCopy')}>{description}</BodyCopy>
            <Spacer initial="1rem" medium="2.8vw" hideOnMobile={!isFeatured} />
            <Button to={null} shouldTransitionIn={false}>
              <BodyCopy>Read post</BodyCopy>
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element,
  description: PropTypes.string,
  exiting: PropTypes.bool,
  expandOnHover: PropTypes.bool,
  isActive: PropTypes.bool,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  isFeatured: PropTypes.bool,
}

export default Content
